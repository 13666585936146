// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agile-lifecycle-management-js": () => import("./../../../src/pages/agile-lifecycle-management.js" /* webpackChunkName: "component---src-pages-agile-lifecycle-management-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-estimate-app-cost-js": () => import("./../../../src/pages/estimate-app-cost.js" /* webpackChunkName: "component---src-pages-estimate-app-cost-js" */),
  "component---src-pages-eu-project-js": () => import("./../../../src/pages/eu-project.js" /* webpackChunkName: "component---src-pages-eu-project-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-mobile-app-development-poland-js": () => import("./../../../src/pages/services/mobile-app-development-poland.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-poland-js" */),
  "component---src-pages-services-poland-software-outsourcing-js": () => import("./../../../src/pages/services/poland-software-outsourcing.js" /* webpackChunkName: "component---src-pages-services-poland-software-outsourcing-js" */),
  "component---src-pages-services-web-app-development-poland-js": () => import("./../../../src/pages/services/web-app-development-poland.js" /* webpackChunkName: "component---src-pages-services-web-app-development-poland-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/Job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-tech-js": () => import("./../../../src/templates/Tech.js" /* webpackChunkName: "component---src-templates-tech-js" */)
}

