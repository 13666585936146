import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ children, to, ...rest }) => {
  const isTranslationActive = process.env.GATSBY_ZAVEN_TRANSLATIONS;

  const nonTranslatedPathnames = ['career', 'projects', 'about', 'agile-lifecycle-management', 'eu-project'];

  const isPageTranslated = nonTranslatedPathnames.some(pathname => to.toLowerCase().includes(pathname.toLowerCase()));

  return isPageTranslated && isTranslationActive ? <a href={to} {...rest}>{children}</a> : <GatsbyLink to={to} {...rest}>{children}</GatsbyLink>
}

Link.defaultProps = {
  prefetch: true
}

export default Link
